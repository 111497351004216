export default {
  namespaced: true,
  state: {
    userData: null,
    
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
  
  },
  getters: {
      userData(state) {
      return state.userData;
    },
  
  },
  actions: {
    updateUserData({ commit }, userData) {
      commit('setUserData', userData);
    },
  
  }
};
