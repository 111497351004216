import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import {BASE_URL} from '@/helpers/Url/BaseUrl';
Vue.use(VueRouter)
var userInfo =JSON.parse(localStorage.getItem('userData'))
console.log('şşş',userInfo);
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Anasayfa',
        breadcrumb: [
          {
             text: 'Ekranlar',
            active: true,
          },
        ],
      },
    },
  
    {
      path: '/productivity' ,
      name: 'productivity',
      component: () => import('@/views/pages/productivity/index.vue'),
      meta: {
        pageTitle: 'Verimlilik',
        breadcrumb: [
          {
             text: 'Kullanıcılar',
               to: { name: 'productivity' },
            active: true,
          },
          
        ],
          
      },
    
    },
    {
      path: '/productivity/:id' ,
      name: 'productivity:id',
      component: () => import('@/views/pages/productivity/index.vue'),
      meta: {
        pageTitle: 'Verimlilik',
        breadcrumb: [
          {
             text: 'Kullanıcılar',
               to: { name: 'productivity' },
            active: true,
          },
          
        ],
          
      },
    
    },
    {
      path:'/user/:user_id',
      name: 'profile',
      component: () => import('@/views/pages/productivity/profile/Profile.vue'),
      meta: {
        pageTitle: 'Kullanıcı Profili',
        breadcrumb: [
          {
            text: 'Verimlilik',
            to: { name: 'productivity' }, // , params: { id: this.$route.params.id } 
            active: false,
           
          },
          {
            text: 'Kullanıcı',
            active: true,
          },
        ],
      },
    
      
    },
    {
      path: '/user-settings',
      name: 'user-settings',
      component: () => import('@/views/pages/settings/users-edit/user.vue'),
      meta: {
      
        pageTitle: 'Kullanıcı ayarları',
        breadcrumb: [
          {
            text: 'Kullanıcılar',
            to: { name: 'user-settings' }, // Updated path
            active: true,
           
          },
         
          // {
          //   text: 'Profile',
          //   active: true,
          // },
        ],
        
      },
    
    },
    {
        
      path: '/user-settings/edit/:id',
      name: 'user-edit',
      component: () => import('@/views/pages/settings/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'User Edit',
        breadcrumb: [
          {
            text: 'Users',
            to: { name: 'user-settings' }, // Updated path
            active: false,
           
          },
          {
            text: 'Edit',
            to: { name: 'user-edit' }, // Updated path
            active: true,
           
          },
          // {
          //   text: 'Profile',
          //   active: true,
          // },
        ],
        
      },
    
  },
  {
        
    path: '/department-settings',
    name: 'department-settings',
    component: () => import('@/views/pages/settings/departments-edit/departments.vue'),
    meta: {
      pageTitle: 'Departman ayarları',
      breadcrumb: [
        {
          text: 'Departmanlar',
          to: { name: 'department-settings' }, // Updated path
          active: false,
         
        },
        // {
        //   text: 'Edit',
        //   to: { name: 'user-edit' }, // Updated path
        //   active: true,
         
        // },
        // {
        //   text: 'Profile',
        //   active: true,
        // },
      ],
      
    },
  
},
{
        
  path: '/department-settings/edit/:id',
  name: 'department-edit',
  component: () => import('@/views/pages/settings/departments-edit/departmentEdit.vue'),
  meta: {
    pageTitle: 'Departman ayarları',
    breadcrumb: [
      {
        text: 'Departmanlar',
        to: { name: 'department-settings' }, // Updated path
        active: false,
       
      },
      // {
      //   text: 'Edit',
      //   to: { name: 'user-edit' }, // Updated path
      //   active: true,
       
      // },
      // {
      //   text: 'Profile',
      //   active: true,
      // },
    ],
    
  },

},
{
        
  path: '/project-settings',
  name: 'project-settings',
  component: () => import('@/views/pages/settings/projects-edit/projects.vue'),
  meta: {
    pageTitle: 'Proje ayarları',
    breadcrumb: [
      {
        text: 'Projeler',
        to: { name: 'project-settings' }, // Updated path
        active: false,
       
      },
      // {
      //   text: 'Edit',
      //   to: { name: 'user-edit' }, // Updated path
      //   active: true,
       
      // },
      // {
      //   text: 'Profile',
      //   active: true,
      // },
    ],
    
  },

},
{
        
  path: '/project-settings/edit/:id',
  name: 'project-edit',
  component: () => import('@/views/pages/settings/projects-edit/projectEdit.vue'),
  meta: {
    pageTitle: 'Proje ayarları',
    breadcrumb: [
      {
        text: 'Projeler',
        to: { name: 'project-settings' }, // Updated path
        active: false,
       
      },
      // {
      //   text: 'Edit',
      //   to: { name: 'user-edit' }, // Updated path
      //   active: true,
       
      // },
      // {
      //   text: 'Profile',
      //   active: true,
      // },
    ],
    
  },

},
{
  path: '/productivity-settings',
  name: 'productivity-settings',
  component: () => import('@/views/pages/settings/productivity-edit/productivity.vue'),
  meta: {
    pageTitle: 'Verimlilik ayarları',
    breadcrumb: [
      {
        text: 'Verimlilik',
        to: { name: 'productivity-settings' },
        active: false,
      },
    ],
  },

},
{
  path: '/edit/:id', // Göreli yol
   name: 'productivity-edit',
  component: () => import('@/views/pages/settings/productivity-edit/productivityEdit.vue'),
  meta: {
    pageTitle: 'Departman verimliliği',
    breadcrumb: [
      {
        text: 'Departman',
        to: { name: 'productivity-settings' },
        active: false,
      },
    ],
  },
},
    {
      path: '/video',
      name: 'video',
      component: () => import('@/views/pages/productivity/profile/logScreen.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/pages/productivity/profile/testProfile.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/pages/settings/reports.vue'),
      meta: {
        pageTitle: 'Raporlar Sayfası',
        breadcrumb: [
          {
            text: 'Raporlar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/views/pages/authentication/forgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password',
      name: 'auth-reset-password',
      component: () => import('@/views/pages/authentication/resetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
   // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    let role = userData.isAdmin ? 'admin':'client'
    next(getHomeRouteForLoggedInUser(userData ? role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
