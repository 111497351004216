export default {
    namespaced: true,
    state: {
      screensLog: null,
      screenDates:null
    },
    mutations: {
      setScreensLog(state, screensLog) {
        state.screensLog = screensLog;
      },
      setScreenDates(state, screenDates) {
        state.screenDates = screenDates;
      }
    },
    getters: {
      screensLog(state) {
        return state.screensLog;
      },
      screenDates(state) {
        return state.screenDates;
      }
    },
    actions: {
      updateScreensLog({ commit }, screensLog) {
        commit('setScreensLog', screensLog);
      },
      updateScreenDates({ commit }, screenDates) {
        commit('setScreenDates', screenDates);
      }
    }
  };